import React, { useEffect ,Fragment } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import LandingPage from './pages/LandingPage/LandingPage';
import HomePage from './pages/HomePage/HomePage';
import UploadPage from './pages/UploadPage/UploadPage'; 
import Footer from './components/Footer/Footer';
import  weddings  from './weddings';
import './App.css';

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="container" data-aos="fade-zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="1000">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path='/' element={<LandingPage/>} />
          {weddings.map((wedding,index)=> {
            return (
              <Fragment key={index} >
                  <Route 
                    path={`/${wedding.groomName}&${wedding.brideName}`} 
                    element={<HomePage 
                                    brideName={wedding.brideName}
                                    groomName={wedding.groomName}
                                    backgroundImage={wedding.backgroundImage} />} />
                  <Route 
                    path={`/${wedding.groomName}&${wedding.brideName}/upload`}
                    element={<UploadPage 
                                  backgroundImage={wedding.backgroundImage}
                                  brideName={wedding.brideName} 
                                  groomName={wedding.groomName} />} />

              </Fragment>
            )
          }) }
          
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
