// import  backgroundImage from '../src/images/background.jpeg'
import  backgroundImage1 from '../src/images/Rawad&Karen.jpeg'
import backgroundImage from '../src/images/Emad&Haya.jpeg'

const weddings = [
{
    brideName: "Karen",
    groomName : "Rawad",
    backgroundImage : backgroundImage1
},
{
    brideName: "Haya",
    groomName : "Emad",
    backgroundImage : backgroundImage
}
]


export default  weddings;